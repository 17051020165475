define('m10/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    debug: true,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);

          var projektdatendata = application.get('model').projektdaten.objectAt(0);
          var projektdatendataJSON = JSON.stringify(projektdatendata);

          var traegerdata = application.get('model').traeger.objectAt(0);
          var traegerdataJSON = JSON.stringify(traegerdata);

          var queranschlussdata = application.get('model').queranschluss.objectAt(0);
          var queranschlussdataJSON = JSON.stringify(queranschlussdata);

          var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

          if (self.debug) {
            console.log("projektdatendata: " + projektdatendataJSON);
            console.log("applicationdataJSON: " + applicationdataJSON);
            console.log("traegerdataJSON: " + traegerdataJSON);
            console.log("queranschlussdataJSON: " + queranschlussdataJSON);
            console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
          }

          var uebergabedaten = $.extend(true, JSON.parse(traegerdataJSON), JSON.parse(queranschlussdataJSON), JSON.parse(lasteinwirkungdataJSON));

          var timberelement = {
            "Querschnittswerte": {
              "b": traegerdata.get('TimberElement_b'),
              "h": traegerdata.get('TimberElement_h')
            },
            "ALFA_RAD_FastenerGrain": traegerdata.get('ALFA_RAD_FastenerGrain'),
            "vorgebohrt": traegerdata.get('TimberElement_Predrilled')
          };

          uebergabedaten.TimberElement = timberelement;

          var JSONdata = JSON.stringify(uebergabedaten);

          var application = self.controllerFor('application');
          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M10 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de",
              suffix: "hi10"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    }
  });

});